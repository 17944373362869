<template>
  <component
    :is="isLink ? NuxtLink : 'div'"
    :class="{
      'block hover:bg-gray-50 ring-gray-50 hover:ring-8 transition-all rounded-sm':
        isLink,
    }"
    :href="href"
    :target="target"
    :to="to"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { RouteLocationRaw } from "#vue-router";

const properties = defineProps<{
  to?: RouteLocationRaw;
  href?: string;
  target?: "_blank";
}>();

const NuxtLink = resolveComponent("nuxt-link");

const isLink = computed(() => properties.to ?? properties.href);
</script>
